import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SHOW_MODAL } from "../Redux/types";
import ElbetFunds from "../pages/TransferElbet";
import { useDispatch } from "react-redux";
export const AccountMenu = ({ componentClase, user }) => {
  const [isCancel, setCancel] = useState({ show: false, ticketId: "" });
  const dispatch = useDispatch();
  const location = useLocation();

  const closeModal = () => {
    setCancel({ ...isCancel, show: false });
  };
  const isCurrentPath = (paths) => {
    return paths.includes(location.pathname);
  };
  const pathsWithBlueBackground = [
    "/Account/Dashboard",
    "/Account/BetList",
    "/Account/TransactionList",
  ];

  return (
    <div className={componentClase}>
      <ul>
        <li>
          <a title="My Account" href="#">
            My Account
          </a>
          <ul>
            {user?.role === "Shop" && (
              <li>
                <NavLink title="Dashboard" to="/Account/Dashboard">
                  Dashboard
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                title="Bet List"
                to="/Account/BetList"
                style={{
                  backgroundColor: isCurrentPath(["/Account/BetList"])
                    ? "#fdd835"
                    : "",
                }}
              >
                Bet List
              </NavLink>
            </li>
            <li>
              <NavLink
                title="Bet List"
                to="/Account/BetListPayout"
                style={{
                  backgroundColor: isCurrentPath(["/Account/BetListPayout"])
                    ? "#fdd835"
                    : "",
                }}
              >
                Bet List Payout
              </NavLink>
            </li>

            <li>
              <NavLink
                title="Coupon Bet List"
                to="/Account/CouponBetList"
                style={{
                  backgroundColor: isCurrentPath(["/Account/CouponBetList"])
                    ? "#fdd835"
                    : "",
                }}
              >
                Coupon Bet List
              </NavLink>
            </li>
            <li>
              <NavLink
                title="Transactions List"
                to="/Account/TransactionList"
                style={{
                  backgroundColor: isCurrentPath(["/Account/TransactionList"])
                    ? "#fdd835"
                    : "",
                }}
              >
                Transactions List
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                title="User Transactions List"
                to="/Account/TransactionList"
              >
                User Transactions List
              </NavLink>
            </li> */}

            {user?.role !== "Cashier" && (
              <>
                <li>
                  <NavLink
                    title="Deposit"
                    to="/Account/Deposit"
                    style={{
                      backgroundColor: isCurrentPath(["/Account/Deposit"])
                        ? "#fdd835"
                        : "",
                    }}
                  >
                    Deposit
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    title="Withdraw"
                    to="/Account/Withdrawal"
                    style={{
                      backgroundColor: isCurrentPath(["/Account/Withdraw"])
                        ? "#fdd835"
                        : "",
                    }}
                  >
                    Withdraw
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </li>
        {user?.role !== "Cashier" && (
          <li>
            <a title="Change Password" href="#">
              PDR
            </a>
            <ul>
              {user?.role !== "Cashier" && (
                //   <>
                //     <li className="uxr-li-link">
                //       <NavLink to="/Account/NewUser?usertype=cashier">
                //         New Cashier
                //       </NavLink>
                //     </li>
                //   </>
                // ) : (
                <li className="uxr-li-link">
                  <NavLink to="/Account/NewUser?usertype=player">
                    New User
                  </NavLink>
                </li>
              )}
              {(user?.role === "Master Agent" ||
                user?.role === "Super Agent" ||
                user?.role === "Agent") && (
                <li className="uxr-li-link">
                  <NavLink to="/Account/NewUser?usertype=shop">
                    New Shop
                  </NavLink>
                </li>
              )}
              {(user?.role === "Master Agent" ||
                user?.role === "Super Agent") && (
                <li className="uxr-li-link">
                  <NavLink to="/Account/NewUser?usertype=agent">
                    New Agent
                  </NavLink>
                </li>
              )}
              {/* <li className="uxr-li-link">
              <a href="https://globalbet.virtual-horizon.com/engine/backoffice/login_BU.htm#accounts"
                  target="_blank" className="level-2">
                  Virtual Credit
              </a>
            </li> */}
              {user?.role !== "Cashier" && (
                <li className="uxr-li-link">
                  <NavLink to="/Account/AgencyList">User List</NavLink>
                </li>
              )}

              {user?.role == "Shop" && (
                <>
                  {/* <li
                  className="uxr-li-link"
                  onClick={() =>
                    dispatch({
                      type: SHOW_MODAL,
                      payload: {
                        open: true,
                        title: "TRANSFER TO ELBET ACCOUNT",
                        component: (
                          <ElbetFunds
                            toggle={closeModal}
                            typeOfTrans="transfer"
                          />
                        ),
                      },
                    })
                  }
                >
                  <NavLink to="#">Transfer Funds (Elbet)</NavLink>
                </li>
                <li
                  className="uxr-li-link"
                  onClick={() =>
                    dispatch({
                      type: SHOW_MODAL,
                      payload: {
                        open: true,
                        title: "Withdraw Fund From ELBET Account",
                        component: (
                          <ElbetFunds
                            toggle={closeModal}
                            typeOfTrans="withdraw"
                          />
                        ),
                      },
                    })
                  }
                >
                  <NavLink to="#">Withdraw Funds (Elbet)</NavLink>
                </li> */}
                </>
              )}
              <li className="uxr-li-link">
                <NavLink to="/Account/Transfer/internal">
                  {user?.role === "Cashier"
                    ? "Transfer Funds Online"
                    : "Transfer Funds to Cashier"}
                </NavLink>
              </li>
            </ul>
          </li>
        )}
        <li>
          <a title="Reports" href="#">
            Reports
          </a>
          <ul>
            {user?.role == "Shop" && (
              <>
                <li>
                  <NavLink title="Commissions" to="/Account/Commission">
                    Commissions
                  </NavLink>
                </li>
                <li>
                  <NavLink title="Sales" to="/Account/Sales">
                    Sales
                  </NavLink>
                </li>

                <li>
                  <NavLink title="Bonus" to="/Account/Bonus">
                    Bonus
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    title="Change Password"
                    to="/Account/credit-liability"
                  >
                    Credit & Liability
                  </NavLink>
                </li>
              </>
            )}
            {user?.role == "Cashier" && (
              <li>
                <NavLink
                  title="Sales"
                  to="/Account/Sales"
                  style={{
                    backgroundColor: isCurrentPath(["/Account/Sales"])
                      ? "#fdd835"
                      : "",
                  }}
                >
                  Sales
                </NavLink>
              </li>
            )}
          </ul>
        </li>
        <li>
          <a title="Account Detail" href="#">
            Account Detail
          </a>
          <ul>
            <li>
              <NavLink
                title="Change Password"
                to="/Account/ChangePassword"
                style={{
                  backgroundColor: isCurrentPath(["/Account/ChangePassword"])
                    ? "#fdd835"
                    : "",
                }}
              >
                Change Password
              </NavLink>
            </li>
            <li>
              <NavLink
                title="Change Password"
                to="/Account/PersonalDetails"
                style={{
                  backgroundColor: isCurrentPath(["/Account/PersonalDetails"])
                    ? "#fdd835"
                    : "",
                }}
              >
                Personal Data
              </NavLink>
            </li>
            <li>
              <NavLink
                title="Access Logs"
                to="/Account/LoginHistory"
                style={{
                  backgroundColor: isCurrentPath(["/Account/LoginHistory"])
                    ? "#fdd835"
                    : "",
                }}
              >
                View Session
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
